

import React, { useEffect } from "react"
import _ from "lodash"
import { FastField } from "formik"
import { Row } from "react-bootstrap"

import { FORM_COMPONENT, DEFAULT_TYPE } from "./../types/inputTypes"
import { FormattedError } from "../../alerts/FormattedError"

import Alert from '@mui/material/Alert';
import { injectIntl } from "react-intl";

const WizardStep = ({ counter,fields,children,error,inverse=false,initialValues={} ,activeStep=null,intl}) => {


  const renderField = _.memoize(({ name, component, ...props },suffix)  => 
  
  (

    <FastField
    name={name}
    key={name + suffix}
    counter={counter}
    component={FORM_COMPONENT[component] || DEFAULT_TYPE }
    { ...props }
    />
    
  )
  )
    let suffix = 0;
  const renderFields = React.useMemo(()=> (<Row>
    {fields.map((input, i) => {
      suffix ++;
      const { ...field } = input
      return (
          renderField(field,suffix,counter) 
      )
    })}
  </Row>), [fields, renderField,counter])
useEffect(() => {
  const element = document.getElementById("counter")
  if (element){
    element.innerHTML = counter?.props.children
  }
  return () => {
    
  }
}, [counter])
  return (<>
        {!inverse && children}
       { renderFields }

{initialValues.redirect === true && activeStep === 2 && (
  <><Alert
        variant="filled"
        severity="warning"
        style={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "100px",
          fontFamily: "inherit",
          fontStyle: "normal",
        }}
      >
        {intl.formatMessage({
          id: "VACCINATION.FICHE.ANIMAL.ALERT",
        })}
      </Alert><br></br></>
)}

{inverse && children}

         {error && (
              <FormattedError
                message={error}
                className="mt-5 px-25"
                onClose={null}
              />
            )}
  </>)
}


export default injectIntl(WizardStep)
