import React, { useState, useEffect } from 'react';
import { HTTP_METHODS } from "src/constants";
import { makeCall } from "src/helpers";
import { FormattedMessage } from "react-intl"

const Captcha = ({reset=false}) => {
    const [captchaSrc, setCaptchaSrc] = useState('');
    const [captcha, setCaptcha] = useState("");
console.log('reset',reset)
    //this for recaptcha with image 
    const fetchCaptcha = async () => {
        const headers = {
            'Content-Type': 'application/json',
        };
    
        const onResolve = (data) => {
            console.log("***data", data);
            if (data?.data.image) {
                setCaptchaSrc(`data:image/png;base64,${data.data.image}`);
                const sessionId = data.data.sessionId;
                console.log('Session ID:', sessionId);

                // Save sessionId to localStorage if available
                if (sessionId) {
                    localStorage.setItem('sessionId', sessionId);
                }
            } else {
                console.error("Invalid response format. Expected 'image' property:", data);
            }
        };
    
        const onReject = (error) => {
            console.error("Error fetching CAPTCHA:", error);
        };
    
        return new Promise((resolve, reject) => {
            makeCall(
                HTTP_METHODS.GET,
                `/api/generate-captcha?${new Date().getTime()}`,
                null,
                headers
            )
                .then((response) => resolve(onResolve(response)))
                .catch((error) => reject(onReject(error)));
        });
    };
    
    
    useEffect(() => {
        if (reset) {
            fetchCaptcha(); // Fetch a new CAPTCHA when `reset` changes
        }
    }, [reset]);
    useEffect(() => {
        fetchCaptcha();
    }, []);

    return (
        <div>
             <img src={captchaSrc} alt="captcha" style={{height:'125px',width:'250px'}} /> 
            <h3>{captcha}</h3>
            <button
        type="button"

    style={{
      backgroundColor: 'linear-gradient(to right, #7b2ff7, #43b2ff)',
      color: 'gris',
      border: 'none',
      borderRadius: '5px',
      padding: '10px 20px',
      cursor: 'pointer',
      width:'250px',
    }}
    onClick={fetchCaptcha}
  >
<FormattedMessage id="Refresh.CAPTCHA" />
  </button>
        </div>
    );
};

export default Captcha;

